import styled from "@emotion/styled";
import * as React from "react";
import Layout from "../components/Layout";
import SectionContent from "../components/SectionContent";

const List = styled.ol`
  li {
    font-size: 1.2em;
    line-height: 1.5rem;
    margin-bottom: 0.5rem;
  }
`;
// markup
const Page = ({ location }) => {
  return (
    <Layout path={location.pathname} title="דגשים לרצים">
      <SectionContent title="דגשים לרצים">
        <List>
          <li> חובה לחתום על טופס הסרת אחריות מהמארגנים .</li>
          <li>תחנת החלפה מס 8 בקיבוץ בארי לה מדווש, 64 ק&quot;מ מהזינוק.</li>
          <p>
            **במקום יש חנות שפתוחה ובה הרצים יכולים לרכוש ציוד ומזון במהלך
            המרוץ.
          </p>
          <li>אין מדידת זמנים אלקטרונית.</li>
          <li>
            חלה חובה לעבור בכל תחנות ההחלפה ולקבל אישור ממנהל תחנת ההחלפה על כך.
          </li>
          <li>חובה לרוץ עם טלפון למקרה חרום.</li>
          <li>חובה לציין איש קשר ליצירת קשר במידת הצורך.</li>
          <li>החל מ 18:00 חובה לרוץ עם ציוד לריצת לילה.</li>
          <li>זמן לחיתוך והפסקת ריצה לפי מה שיפורסם במסלולים.</li>
          <li>במקרה של אי עמידה בזמני החיתוך יידרש הרץ לעלות על הרכב המאסף.</li>
          <li>בכל התחנות יהיו מים לשתייה, פירות וממרחים ללחם.</li>
        </List>
      </SectionContent>
    </Layout>
  );
};

export default Page;
